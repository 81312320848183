import React from "react"
import { Link } from "gatsby"
import {
  GridBox,
  Standalone,
  Text,
  Title,
} from "@thedgbrt/logology-ui-components"
import SEO from "../../components/seo"

const TosPage = () => (
  <>
    <SEO title="Terms and conditions" />
    <GridBox type={5} colSpans={[[3, 8], [3, 8], [3, 9]]} withBgContainer>
      <Title standalone>Terms and conditions</Title>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Text>
          <b>
            These Terms and Condition are a translated version of the original
            written in French. It may be approximate, only the French version is
            authentic. You can{" "}
            <Link to="/terms-and-conditions-fr">find it here</Link>.
          </b>
        </Text>
      </Standalone>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Text italic>
          LOGOLOGY, a simplified joint stock company with a capital of 5,000
          euros, registered with the Lille RCS under number 879 396 521, whose
          registered office is located at 11 place Simon Vollant 59800 Lille,
          represented by Dagobert Renouf, its manager, and whose email address
          is <a href="mailto:support@logology.co">support@logology.co</a>,
          hereinafter referred to as "Logology".
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Preamble</Title>
        <Text italic>
          Logology is a company specialized in the creation and marketing of
          logos. It designs, creates and develops strong visual identities that
          allow its clients to stand out. Logology attaches particular
          importance to protecting and defending its intellectual property and
          know-how. The Client has approached Logology in order to acquire a
          Logo, visible on Logology's website{" "}
          <Link to="/">www.logology.co</Link>, at the price mentioned on this
          site. Two types of logos are presented on this website:
          <ul>
            <li>Logo with license to use: start</li>
            <li>Logo with license to use: grow</li>
            <li>Logo with license to use: establish</li>
          </ul>
        </Text>
        <Text italic>
          The present conditions govern the relationship between Logology and
          the Client. They constitute the entirety of the Parties' obligations.
          They can only be modified by mutual agreement between the Client and
          Logology in writing. The acquisition of a Logo through Logology's
          website <Link to="/">www.logology.co</Link>, implies the full and
          entire acceptance by the Client of the present Terms and Conditions of
          Sale. These will prevail over any other general or particular
          conditions of the Client. Logology reserves the right to modify these
          Terms and Conditions of Sale at any time. It is, however, agreed that
          the Conditions applicable to the Client will be those in force at the
          date of his order on the Site.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 1. Object of the contract</Title>
        <Text italic>
          The purpose of this contract is to determine the conditions under
          which the Client acquires the Logo, presented on Logology's website{" "}
          <Link to="/">www.logology.co</Link>.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 2. Obligations</Title>
        <Title level={5}>2.1. Logology's obligations</Title>
        <Text italic>
          Logology's service consists of designing and marketing an original
          Logo, via the Logology website <Link to="/">www.logology.co</Link>, in
          a professional manner, taking into account the state of the art and
          according to the customs of the profession.
        </Text>
        <Title level={5}>2.2. Client's obligations</Title>
        <Text italic>
          The Client is responsible for compliance with the legislation specific
          to his activity. The Client undertakes to use the Logo ordered and
          paid for in full, under normal conditions and in accordance with
          Article 5 of these General Terms and Conditions of Sale. The Client is
          prohibited from reproducing, exploiting or modifying any Logo proposed
          by Logology, and which has not been accepted and paid for by the
          Client.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 3. Responsibility</Title>
        <Text italic>
          Logology is subject to an obligation of means. Logology's compensation
          for the prejudice suffered by the Client as a result of this contract
          cannot exceed the price of the Logo. Logology can be exonerated from
          all or part of its responsibility by providing proof that the
          non-performance or poor performance of the contract is attributable
          either to the Client, or to the unforeseeable and insurmountable fact
          of a third party to the contract, or to a case of force majeure.
          Logology cannot be held responsible in case of a computer bug
          occurring during the order process, linked to the Client's internet
          connection.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 4. Logology's Warranties</Title>
        <Text italic>
          Logology guarantees the Client the peaceful enjoyment of the rights
          granted to it against any disturbances, claims or evictions
          whatsoever. It guarantees the Client against any legal action relating
          to the Logo sold, within the limit of the contract price, subject to
          having been informed by registered mail within four working days
          following receipt of a formal notice or a document initiating
          proceedings by the Client. Logology is then the sole master of the
          manner of conducting the action. The Client must provide the following
          information necessary to conduct its defence or reach a settlement
          agreement. In the case of naming services consisting for Logology in
          proposing a product or entity name to the Client, or in the case of a
          Logo that must be adapted to a name initially chosen by the Client
          alone, the Client is personally responsible for the exhaustive legal
          research of the availability of the said name with a view to its
          peaceful exploitation and the registration of a trademark; in his
          professional capacity, Logology can put him in contact with a lawyer
          specialized in intellectual property. All logos supplied by Logology
          benefit from the legal guarantee provided for in articles 1641 and
          following of the French Civil Code. In addition, the Client also
          benefits from the guarantee of conformity of the products, provided by
          articles L. 211-4 and following of the French Consumer Code. All
          claims under this article must be made by mail or by e-mail to{" "}
          <a href="mailto:support@logology.co">support@logology.co</a>
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 5. Intellectual Property</Title>
        <Title level={5}>
          5.1. Rights acquired or held prior to the conclusion of the Contract
        </Title>
        <Text italic>
          Logology remains the owner of all elements protected by intellectual
          property rights acquired and/or held prior to the conclusion of the
          present contract and the payment of the price of the Logo, as well as
          the related rights. The Client is therefore not authorized to exploit,
          represent or reproduce them, in whole or in part, without Logology's
          prior written authorization, given at the latter's sole discretion.
          The contract does not transfer any title of ownership, nor does it
          confer any assignment or concession of intellectual property rights
          existing prior to the conclusion of this contract and the payment of
          the Logo price. Thus, the Client agrees not to reproduce, exploit or
          modify any Logo that Logology proposes to him, and that has not been
          accepted and paid for in full by him.
        </Text>
        <Title level={5}>
          5.2. License of the intellectual property rights pertaining to the
          Logo ordered, for the product entitled "Logo with license to use:
          start".
        </Title>
        <Text italic>
          <ul>
            <li>
              Logology owns the copyright of the original ordered Logo. It is
              invested with all the copyrights on it, in accordance with the
              Intellectual Property Code. Furthermore, concerning any components
              of the Logo that Logology may have brought and of which it is not
              the author, Logology declares that it has obtained all the rights
              necessary for their exploitation, reproduction and use by the
              Client under the conditions below.
            </li>
            <li>
              When the Logo is completed, validated by the Client, and the price
              has been paid in full, Logology will grant certain economic rights
              to the Client as listed below, for the duration of the copyright,
              starting from the payment of the full price, under a non-exclusive
              license of economic rights. This right includes :
            </li>
            <li>
              the right of reproduction: this right includes the right to
              reproduce and use, in particular for commercial, promotional and
              advertising purposes, directly or indirectly by any technical
              process, on any written or paper media (catalogues, prospectuses,
              mailings, etc.). ), plastic, audiovisual, digital, magnetic,
              optical, electronic or multimedia media and on any private or
              open, national or international telecommunications network (and in
              particular the Internet, Intranets and Extranets), the original
              Logo (in whole or in part), the object of the present license, in
              unlimited number; this right also includes the right for the
              Client to entrust the execution of this exploitation to any
              natural person or legal entity of its choice. The right of
              reproduction includes, in particular, the right to digitize, put
              online, download.
            </li>
            <li>
              the right of representation: this right includes the right to
              represent, make accessible, broadcast or communicate to the public
              in any way, directly or indirectly and by any telecommunication
              process and analog or digital images, by hertzian means, by cable
              or satellite, direct or not, by all "on line" services and
              assimilated, all or part of the original Logo for any use that
              could be made by the Client, either for his benefit or for the
              account of a third party.
            </li>
          </ul>
        </Text>
        <Text italic>
          It does not include the right to market, distribute, make available,
          extract, modify, correct, adapt, transfer, license or register a
          trademark. Logology may use for its own benefit, or for the benefit of
          third parties, the Logo whose rights are thus granted. Given the
          universal nature of the Internet, the economic rights thus granted are
          granted for the entire world. Because of the type of work for which
          the economic rights are granted and their destination, the author's
          remuneration under the license will be fixed and included in the
          overall price. The source files will not be delivered to the Client by
          Logology, after payment of the full price mentioned on Logology's
          website www.logology.co.
        </Text>
        <Title level={5}>
          5.3. License of the intellectual property rights pertaining to the
          Logo ordered, for the product entitled "Logo with license to use:
          grow".
        </Title>
        <Text italic>
          <ul>
            <li>
              Logology owns the copyright of the original ordered Logo. It is
              invested with all the copyrights on it, in accordance with the
              Intellectual Property Code. Furthermore, concerning any components
              of the Logo that Logology may have brought and of which it is not
              the author, Logology declares that it has obtained all the rights
              necessary for their exploitation, reproduction and use by the
              Client under the conditions below.
            </li>
            <li>
              When the Logo is completed, validated by the Client, and the price
              has been paid in full, Logology will grant certain economic rights
              to the Client as listed below, for the duration of the copyright,
              starting from the payment of the full price, under a non-exclusive
              license of economic rights. This right includes :
            </li>
            <li>
              the right of reproduction: this right includes the right to
              reproduce and use, in particular for commercial, promotional and
              advertising purposes, directly or indirectly by any technical
              process, on any written or paper media (catalogues, prospectuses,
              mailings, etc.). ), plastic, audiovisual, digital, magnetic,
              optical, electronic or multimedia media and on any private or
              open, national or international telecommunications network (and in
              particular the Internet, Intranets and Extranets), the original
              Logo (in whole or in part), the object of the present license, in
              unlimited number; this right also includes the right for the
              Client to entrust the execution of this exploitation to any
              natural person or legal entity of its choice. The right of
              reproduction includes, in particular, the right to digitize, put
              online, download.
            </li>
            <li>
              the right of representation: this right includes the right to
              represent, make accessible, broadcast or communicate to the public
              in any way, directly or indirectly and by any telecommunication
              process and analog or digital images, by hertzian means, by cable
              or satellite, direct or not, by all "on line" services and
              assimilated, all or part of the original Logo for any use that
              could be made by the Client, either for his benefit or for the
              account of a third party.
            </li>
            <li>
              the right of modification: this right includes the right to
              modify, correct or adapt the Logo in any way deemed appropriate by
              the customer.
            </li>
          </ul>
        </Text>
        <Text italic>
          It does not include the right to market, distribute, make available,
          extract, transfer, license or register a trademark. Logology may use
          for its own benefit, or for the benefit of third parties, the Logo
          whose rights are thus granted. Given the universal nature of the
          Internet, the economic rights thus granted are granted for the entire
          world. Because of the type of work for which the economic rights are
          granted and their destination, the author's remuneration under the
          license will be fixed and included in the overall price.
        </Text>

        <Title level={5}>
          5.4. Assignment of intellectual property rights related to the Logo
          ordered, for the product entitled "Logo and assignment of rights:
          establish".
        </Title>

        <Text italic>
          <ul>
            <li>
              Logology owns the copyright of the original ordered Logo. It is
              invested with all the copyrights on it, in accordance with the
              Intellectual Property Code. Furthermore, concerning the possible
              components of the Logo that Logology would have brought and of
              which it would not be the author, Logology declares that it has
              obtained all the rights necessary for their exploitation,
              reproduction and transfer to the Customer under the conditions
              below.
            </li>
            <li>
              When the Logo is completed, validated by the Customer, and the
              price has been paid in full, Logology will transfer the economic
              copyright, hereafter listed, to the Customer for the duration of
              the copyright.
            </li>
            <li>
              The assignment in full and exclusively without exception or
              reservation to the Customer concerns in particular the rights of
              exploitation, reproduction, representation, use, reuse, trademark
              registration, as well as all associated legal and de facto
              guarantees, with a view to direct and/or indirect exploitation by
              the Customer, for any purpose whatsoever.
            </li>
          </ul>
        </Text>
        <Text italic>
          The transfer covers all economic rights, namely :
          <ul>
            <li>
              the right of reproduction: this right includes the right to
              reproduce and use in particular for commercial, promotional and
              advertising purposes, directly or indirectly by any technical
              process, on any written or paper media (catalogs, prospectuses,
              mailings, etc.). ), plastic, audiovisual, digital, magnetic,
              optical, electronic or multimedia media and on any private or
              open, national or international telecommunications network (and in
              particular the Internet, Intranets and Extranets), the original
              Logo (in whole or in part), the object of the present assignment,
              in unlimited number; this right also includes the right for the
              Customer to entrust the execution of this exploitation to any
              natural person or legal entity of its choice. The right of
              reproduction includes, in particular, the right to digitize, put
              online, download.
            </li>
            <li>
              the right of representation: this right includes the right to
              represent, make accessible, broadcast or communicate to the public
              in any way whatsoever, directly or indirectly and by any
              telecommunication process and analog or digital images, by
              hertzian, cable or satellite means, direct or not, by any "on
              line" services and assimilated, all or part of the original Logo
              for any use that may be made by the Customer, either for his
              benefit or for the account of a third party;
            </li>
          </ul>
        </Text>
        <Text italic>
          It does not include the right to market, distribute, make available,
          extract, transfer or license. Logology may not use, for its own
          benefit or for the benefit of a third party, the Logo whose rights are
          thus assigned, except for professional or commercial reference. Given
          the universal nature of the Internet, the economic rights thus
          assigned are for the entire world. Because of the type of work for
          which the economic rights are assigned and its destination, the
          author's remuneration for the assignment will be fixed and included in
          the overall price.
        </Text>
        <Text italic>
          The source files are given to the Customer by Logology, after payment
          of the full price mentioned on Logology's website www.logology.co.
        </Text>

        <Title level={5}>5.5. Mention of Logology's name</Title>
        <Text italic>
          The name of Logology will be mentioned on the creations proposed by
          her. The Client agrees to make its best efforts to mention the name
          "Logology" on all its communication media and in particular on its
          website.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 6. Price and payment terms</Title>
        <Text italic>
          The Client will pay Logology the price mentioned on Logology's
          website: <Link to="/">www.logology.co</Link>. This sum covers the
          definitive acquisition by the Client of the intellectual property
          rights to Logology's creations, under the conditions set forth in
          article #5. The sale price is due upon order. Payment will be made by
          credit card or bank transfer. The Client's credit card number is only
          directed to the bank's servers, in a secure environment. The prices
          indicated by Logology at the time of the order are denominated in
          Euros including all taxes, according to the VAT at the rate in effect
          on the day of the order. Any change in the rate will be immediately
          reflected in the price of the logos offered. Logology reserves the
          right to modify its prices at any time, it being understood, however,
          that only the prices presented on Logology's website on the day of the
          order will be applicable to the Client. The prices indicated include
          the cost of processing the order.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 7. Order Process</Title>
        <Text italic>
          The Client wishing to place an order on Logology's Website must :
          <ul>
            <li>
              create an account by filling in the information requested on
              Logology's website <Link to="/">www.logology.co</Link> (i.e.
              surname, first name, postal address, e-mail address, phone number)
            </li>
            <li>consult the logos and add the desired Logo to your cart </li>
            <li>checkout</li>
            <li>proceed to checkout </li>
            <li>choose and validate your method of payment</li>
          </ul>
        </Text>
        <Text italic>
          It is up to the Client to ensure the accuracy of the information
          provided, for which he is solely responsible. Logology will
          immediately communicate to the Client by e-mail the confirmation of
          the order recorded. The deadline for delivery will be specified in the
          order confirmation e-mail. All the data provided and the recorded
          confirmation are valid proof of the transaction. Logology will archive
          orders and order confirmations on a reliable and durable medium
          constituting a true copy, in accordance with the provisions of the
          Civil Code. Logology's computerized records will be considered by the
          Parties as proof of communications, orders, payments and transactions
          between the Parties. Logology has subscribed to a distance selling
          contract with its bank. The banking transaction is secure and, in no
          way does Logology keep banking information about the Client. Logology
          reserves the right to refuse an order that is abnormal in nature,
          particularly with regard to the quantities ordered. Logology informs
          the Client that his personal data is registered and that it has made
          the preliminary steps with the CNIL concerning the processing of this
          data, in accordance with the provisions of the French Data Protection
          Act No. 78-17 of January 6, 1978 and the GDPR.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 8. Subcontracting</Title>
        <Text italic>
          The Client authorizes Logology to use subcontractors.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 9. Insurance</Title>
        <Text italic>
          Each of the Parties declares that it holds an insurance policy taken
          out with a company suited to its services, covering its professional
          civil liability and guaranteeing the pecuniary consequences of this
          liability arising from bodily injury, material or immaterial damage,
          whether or not consequential to material or bodily injury, for an
          amount of cover sufficient with regard to the commitments undertaken
          within the framework of this contract. If a Party so requests, the
          other Party shall provide it with a copy of the supporting documents
          showing that its insurance policy is up to date.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 10. References and communication</Title>
        <Text italic>
          In accordance with the practices of the profession, the Client grants
          Logology the right to communicate about the collaboration between the
          Parties and the name of the Client, by any means (newsletter, website,
          blog, social networks, conferences, press...) within the framework of
          Logology's commercial and/or technical promotion (through the
          realization of case studies).
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 11. Termination</Title>
        <Text italic>
          In the event of serious breach by the Client of the obligations of
          this contract that is not remedied within 10 (ten) days from the date
          of the registered letter with acknowledgement of receipt notifying the
          said breach, this contract will be automatically terminated at the
          Client's expense, without prejudice to any damages that Logology may
          claim. The Client will therefore no longer be able to use the Logo.
          The fact that one of the Parties does not take advantage of a failure
          by the other Party to comply with any of the obligations set forth
          herein shall not be interpreted in the future as a waiver of the
          obligation in question.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>
          Article 12. Applicable law and competent jurisdiction
        </Title>
        <Text italic>
          This Agreement is subject to French law. The Parties agree to make
          every effort to settle amicably all disputes arising from the
          conclusion, interpretation, performance or termination of this
          Agreement.
        </Text>
        <Text italic>
          In the absence of an amicable agreement, any dispute between the
          Parties relating to the conclusion, interpretation, execution or
          transfer of the Contract for any reason whatsoever, as well as any
          consequences that may result from it, will be submitted to the courts
          of the jurisdiction of Lille Metropole, notwithstanding any appeal in
          warranty or plurality of defendants, including in the case of
          emergency or protective proceedings, in summary proceedings or by
          petition.
        </Text>
      </Standalone>
    </GridBox>
  </>
)

export default TosPage
